import React, { useState, useEffect } from 'react'
import {
  Nav,
  Navbar,
  NavDropdown,
  Container,
  Form,
  FormControl,
  Button,
  Image
} from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import { Dropdown } from 'react-bootstrap'
import "./header-style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'


const Header = (props) =>{
  const [wallAddress, setWallAdress] = useState('')
  const [cookies, setCookies] = useCookies(['address'])
  useEffect(() => {
    if (cookies.address != undefined) {
      setWallAdress(cookies.address)
    }
  }, [cookies.address])
  const ConnectWallet = () => {
    if (wallAddress === "") {
      let provider = window.ethereum

      provider
        .request({ method: 'eth_requestAccounts' })
        .then(accounts => {
          let selectedAccount = accounts[0];
          setCookies("address",selectedAccount)
          setWallAdress(accounts[0])
          console.log(wallAddress)
        })
        .catch(err => {
          console.log(err)
        })

      window.ethereum.on('accountsChanged', accounts => {
        let selectedAccount = accounts[0]
        // setWallAdress(wallAddress)
      })
    }
    setWallAdress(wallAddress)
  }

  return(
        <header id="header">
            <div className="header--content">
                <Navbar expand="lg" className="navbar-bg">
                    <div className="container-fluid">
                        <Navbar.Brand href="#">
                            <Image src="images/Header-Logo.gif" alt="" fluid/>
                        </Navbar.Brand>
                        <Navbar.Toggle/>
                        <Navbar.Collapse className="">
                            <Nav className="my-2 my-lg-0 navbar-nav">
                                <Nav.Link href="https://app-ritz.arhamsoft.info/"><img src="/images/menu/home.svg" className="img-fluid" alt="Home icon" />Home</Nav.Link>
                                <Nav.Link href="https://app-ritz.arhamsoft.info/swap"><img src="/images/menu/swap.svg" alt="Swap icon" />Swap</Nav.Link>
                                <Nav.Link href="https://app-ritz.arhamsoft.info/pools"><img src="/images/menu/pools.svg" alt="Pools icon" />Pools</Nav.Link>
                                <Nav.Link href="https://app-ritz.arhamsoft.info/liquidity"><img src="/images/menu/liquidity.svg" alt="Liquidity icon" />Liquidity</Nav.Link>
                                <Nav.Link href="https://app-ritz.arhamsoft.info/farms"><img src="/images/menu/farm.svg" alt="Farm icon" />Farm</Nav.Link>
                                <Nav.Link href="/"><img src="/images/menu/bridge.svg" alt="Bridge icon"/>Bridge</Nav.Link>
                                {/* <NavDropdown title="More" id="navbarScrollingDropdown" className="dropdown">
                                    <div className="dropdown-menu">
                                        <NavDropdown.Item href="https://analytics-ritz.arhamsoft.info/home">Analytics</NavDropdown.Item>
                                        <NavDropdown.Item href="http://18.118.95.134/">Docs</NavDropdown.Item>
                                    </div>
                                </NavDropdown> */}
                                 {/* <Nav.Link href="/" className="has-dropdown">
                                   <img src="/images/menu/more.svg" alt="Bridge icon"/>More</Nav.Link>
                                 <div class="dropdown-sub-menu">
                                    <Nav.Link href="https://analytics-ritz.arhamsoft.info/home">Analytics</Nav.Link>
                                    <Nav.Link href="http://18.118.95.134/">Docs</Nav.Link>
                                  </div> */}

                                  <Dropdown className="more-dropdown">
                                    <Dropdown.Toggle id="dropdown-basic" className="nav-link">  
                                    <img src="/images/menu/more.svg" alt="More icon"/>More </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="https://analytics-ritz.arhamsoft.info/home">Analytics</Dropdown.Item>
                                        <Dropdown.Item href="http://18.118.95.134/">Docs</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>


                            </Nav>
                        </Navbar.Collapse>
                        {wallAddress==""?
                        <div className="enter-dApp-button">
                            <a href="#">
                                <Button type="button" className="btn"  onClick={ConnectWallet}><img src="/images/menu/connect-wallet.svg" alt="Wallet Icon" className="wallet-icon"/><span>Connect Wallet</span></Button>
                            </a>
                        </div>
                        :
                        <div className="wallet-btn-wrapper">
                            <div className="contect-wallet-btn " >
                                <div className="icon-wrap ">
                                    <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM ACFFk"><path fillRule="evenodd" clipRule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path></svg>
                                </div>
                                <span className="wallet-title  ">{wallAddress}</span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </div>
                            <div className="wallet-content">
                                <div className="btn-wrap">
                                    <button type="button" className="text-capitalize">wallet
                                    <span><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                                    </button>
                                    <button type="button" className="text-capitalize">disconnect
                                    <span><FontAwesomeIcon icon={faArrowCircleRight} /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
}
                    </div>
                </Navbar>
            </div>
        
        </header>
  )
  }

export default Header
