import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Component/Home'
import Bridge from './Component/Bridge'
import BridgeDetail from './Component/BridgeDetail'
import { BrowserRouter } from 'react-router-dom'

function App () {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/' element={<Bridge />} /> */}
        <Route path='bridge-detail' element={<BridgeDetail />}>
          <Route path=':transId' element={<BridgeDetail />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
