import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import Header from './Header'
import { useCookies } from 'react-cookie'
const BridgeDetail = props => {
  const [cookies, setCokies] = useCookies(['userId'])
  const [transDetail, setTransDetail] = useState({})
  const [err, setError] = useState('')
  useEffect(() => {
    const userId = cookies.userId

    // console.log(cookVal)
    if (userId != undefined)
      axios
        .get(`http://18.118.82.246:3000/v1/users/singleUser`, {
          params: { userId }
        })
        .then(res => {
          setError("");
          setTransDetail(res.data.userDetails)

        })
        .catch(err => {
          // setError('Check Internet Connection /Data Null')
          console.log(err)
        })
  }, [])
  console.log(err)
  return (
    <Fragment>
      <Header />
      <div className='bridge-deatil-sec'>
        <div className='details-box'>
          <div className='table-responsive'>
          
            <Table className='table bridge-table align-middle'>
             
                <tbody>
                  <tr>
                    <td>Source Hash:</td>
                    <td className='link'>
                        {transDetail.sourceHash}
                    </td>
                  </tr>
                  <tr>
                    <td>Dest Hash:</td>
                    <td>
                        {transDetail.destHash}
                    </td>
                  </tr>
                  <tr>
                    <td>Source Chain:</td>
                    <td>{transDetail.senderNetwork}</td>
                  </tr>
                  <tr>
                    <td>Dest Chain:</td>
                    <td>{transDetail.receiverNetwork}</td>
                  </tr>

                  <tr>
                    <td>From:</td>
                    <td>{transDetail.fromWalletAddress}</td>
                  </tr>
                  <tr>
                    <td>To:</td>
                    <td>
                        {transDetail.toWalletAddress}
                    </td>
                  </tr>
                  <tr>
                    <td>Date:</td>
                    <td>{transDetail.createdAt}</td>
                  </tr>
                  <tr>
                    <td>CoinType:</td>
                    <td>{transDetail.coinType}</td>
                  </tr>
                  <tr>
                    <td>Send Value:</td>
                    <td>{transDetail.bridgeValue}</td>
                  </tr>
                  <tr>
                    <td>Receive Value:</td>
                    <td>{transDetail.bridgeValue}</td>
                  </tr>
                  <tr>
                    <td>Status:</td>
                    <td>{transDetail.status}</td>
                  </tr>
                  <tr>
                    <td>Type:</td>
                    <td>{transDetail.transactionType}</td>
                  </tr>
                </tbody>
              
            </Table>
           
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default BridgeDetail
